<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
  </div>
</template>

<script>
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';
import navMenuItems from '@/navigation/full'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      navMenuItems
    };
  },
  methods: {
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
